import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";

// Global State
import AuthContext from "../../Store/auth-context";

// Components
import ButtonPillUppercase from "./Buttons/ButtonPillUppercase";

// Models
import ICategories from "../../Models/ICategories";
import {IPerson} from "../../Models/IPerson";

// Api
import {AddOrEditPerson, GetIsOnboarded} from "../../Api/Person";
import {Colours} from "../../Constants/Colours";
import InputDropDown from "./Inputs/InputDropDown";
import {Ease} from "../../Constants/EasingCurves";
import {ResponsiveBreakpoints} from "../../Constants/ResponsiveBreakpoints";
import {IconCheckMark, IconCheckMarkNoCircle} from "../../Assets/Icons/Icons";

const UserOnboardingWrapper = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: -200%;
    left: 0;
    z-index: 1000;
    background: rgba(0,0,0,0.8);
    opacity: 0;
    transform: scale(1.05);
    min-height: 100vh;
    overflow-y: auto;
    transition: opacity .45s ${Ease.Smooth}, transform .45s ${Ease.Smooth}, top 0s linear .45s;
    &.is-active {
        opacity: 1;
        top: 0;
        transform: scale(1.01);
        transition: opacity .45s ${Ease.Smooth}, transform .45s ${Ease.Smooth}, top 0s linear;
    }

    .popup__content {
        width: calc(100% - 2rem);
        max-width: calc(600rem/16);
    }

    & > div {

        margin: 1rem;
        width: calc(100% - 2rem);
    }

    .user-onboard__wrapper {
        padding: 1rem;
        background: #0E0E0EFF;
        color: white;
        border-radius: calc(10rem/16);
        width: 100%;
        transition: height .45s ${Ease.Smooth};
        box-shadow: 0 0 calc(15rem/16) 0 black;
        .user-onboard__steps {
            display: flex;
            align-items: flex-start;
            transition: transform .45s ${Ease.Smooth};
        }

        .user-onboard__step {
            width: 100%;
            opacity: 0;
            transition: opacity .45s ${Ease.Smooth};
            position: relative;
            &.is-active {
                opacity: 1;
            }
        }

        h1 {
            margin: 0 0 .5rem 0;
            line-height: 1.3;
            font-family: "Outfit", Helvetica, sans-serif;
            font-size: calc(25rem/16);
            font-weight: bold;
            @media screen and (min-width: calc(${ResponsiveBreakpoints.TabletBreakpoint}em/16)) {
                font-size: calc(30rem/16);
            }
        }

        h2 {
            margin: 0;
            line-height: 1.3;
            font-weight: bold;
            font-family: "Outfit", Helvetica, sans-serif;
        }

        h3 {
            font-family: "Manrope", Helvetica sans-serif;
            margin: 0 0 1.5rem 0;
            font-size: calc(12rem/16);
            line-height: 1.4;
        }

        .user-onboard__heading-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                width: calc(100% - (90rem/16));
            }

            span {
                cursor: pointer;
                transform: translateY(calc(3rem/16));
                &:hover,
                &:focus,
                &:active {
                    color: ${Colours.IckonicPinkHighlight};
                }
            }
        }

        .user-onboard__close {
            font-size: calc(12rem/16);
            color: #808080;
        }

        p {
            margin: 1rem 0 1.5rem 0;
            font-family: "Rubik", Helvetica, sans-serif;
            font-size: calc(16rem/16);
        }

        .user-onboard__divider {
            background: linear-gradient(45deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
            height: calc(2rem/16);
            width: 100%;
            margin: 1.5rem 0 1rem 0;
        }

        .multi-select-buttons {
            .ickonic-button {
                font-size: calc(10rem/16);
                border-radius: calc(7rem/16);
                margin: 0 calc(8rem/16) calc(8rem/16) 0;
                display: inline-block;
                padding: calc(7rem/16) calc(10rem/16);
                box-shadow: none;
                transition: none;
                font-family: "Manrope", Helvetica sans-serif;
                background: none;
                letter-spacing: calc(1rem/16);
                &:hover {
                    &.is-not-active.is-not-dimmed {
                        background: white;
                        color: black;
                    }
                }

                &.dimmed {
                    opacity: 0.5;
                    cursor: inherit;
                }
            }
        }

        .user-onboard__button-wrapper {
            display: flex;
            justify-content: space-between;
            .ickonic-button {
                width: calc(80rem/16);
                padding: calc(7rem/16) calc(10rem/16);
            }
        }

        .user-onboard__step-one {
            .ickonic-button__next-step {
                &.is-active {
                    box-shadow: inset 0 0 0 calc(2rem/16) ${Colours.IckonicPink};
                    color: $white;
                    background: #0E0E0EFF;
                    &:hover,
                    &:focus,
                    &:active {
                        color: $white;
                        background: #0E0E0EFF;
                        box-shadow: inset 0 0 0 calc(4rem/16) ${Colours.IckonicPink};
                    }
                }
            }
        }

        .ickonic-button__next-step,
        .ickonic-button__prev-step,
        .ickonic-button__finish-step {
            margin: 1rem 0 0 0;
            font-size: calc(14rem/16);
            font-family: "Manrope", Helvetica sans-serif;
            box-shadow: none;
            transition: box-shadow .25s ${Ease.Smooth}, color .25s ${Ease.Smooth}, background .25s ${Ease.Smooth};
            &.is-active {
                box-shadow: inset 0 0 0 0 ${Colours.IckonicPinkDesaturatedDark};
                color: $white;
                background: ${Colours.IckonicPink};
                &:hover,
                &:focus,
                &:active {
                    background: ${Colours.IckonicPinkHighlight};
                }
            }

            &.is-not-active {
                background: #202020;
                cursor: inherit;
                color: #404040;
            }
        }

        .user-onboard__step-four {
            &.is-active {
                .user-onboard__gradient-circle {
                    transform: scale(1);
                }
            }

            h1 {
                text-align: center;
                width: 100%;
            }

            p {
                text-align: center;
                width: 100%;
                max-width: calc(450rem/16);
                margin: 1rem auto 2rem auto;
            }

            .ickonic-button {
                &.is-active {
                    background: linear-gradient(45deg, rgba(229, 174, 44, 1) 0%, rgba(187, 54, 184, 1) 100%);
                    color: white;
                    font-weight: bold;
                    text-shadow: calc(1rem/16) calc(1rem/16) calc(3rem/16) black;
                    &:hover,
                    &:focus,
                    &:active {
                        background: linear-gradient(45deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
                    }
                }
            }
        }

        .ickonic-button__finish-step {
            &.is-active {
                color: black;
                background: ${Colours.Accent};
                &:hover,
                &:focus,
                &:active {
                    background: ${Colours.AccentDark};
                }
            }
        }

        .user-onboard__gradient-circle {
            position: relative;
            width: calc(70rem/16);
            height: calc(70rem/16);
            margin: 0 auto 2rem auto;
            background: linear-gradient(45deg, rgba(229,174,44,1) 0%, rgba(187,54,184,1) 100%);
            border-radius: 50%;
            overflow: hidden;
            transform: scale(0);
            transition: transform .45s cubic-bezier(0.495, 0.095, 0.200, 1.225) .25s;
            svg {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }

        .user-onboard__gradient-cover {
            position: absolute;
            top: calc(4rem/16);
            left: calc(4rem/16);
            width: calc(100% - (8rem/16));
            height: calc(100% - (8rem/16));
            background: rgba(14,14,14,1);
            border-radius: 50%;
        }
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.LargeMobileBreakpoint}em/16)) {
        .user-onboard__wrapper {
            .user-onboard__button-wrapper {
                .ickonic-button {
                    width: calc(120rem/16);
                }
            }
        }
    }

    @media screen and (min-width: calc(${ResponsiveBreakpoints.SmallTabletBreakpoint}em/16)) {
        & > div {
            margin: 0 2rem;
            width: calc(100% - 4rem);
        }

        .user-onboard__wrapper {
            padding: 2rem;
            .user-onboard__button-wrapper {
                .ickonic-button {
                    width: calc(170rem/16);
                    padding: calc(10rem / 16) calc(25rem / 16);
                }
            }
        }
    }


    // @media screen and (min-width: calc(${ResponsiveBreakpoints.LargeMobileBreakpoint}em/16)) and (min-height: calc(800em/16)) {
    //     .user-onboard__wrapper {
    //         top: 15vh;
    //
    //     }
    // }

    @media screen and (min-width: calc(760em/16)) {
        .user-onboard__wrapper {
            width: calc(696rem/16);
            left: calc(50% - (348rem/16));
            h3 {
                font-size: calc(14rem/16);
            }

            .multi-select-buttons {
                .ickonic-button {
                    padding: calc(7rem/16) calc(25rem/16);
                    margin: 0 1rem 1rem 0;
                    font-size: calc(12rem/16);
                }
            }
        }
    }
`;

const UserOnboarding = () => {
    const
        userAuthenticationContext = useContext(AuthContext),
        [onBoardPopUpActive, setOnBoardPopUpActive] = useState(false),
        [allowClosePopUp, setAllowClosePopUp] = useState(false),
        [personData, setPersonData] = useState<Partial<IPerson>>({
            ...userAuthenticationContext?.UserDetails?.Person,
            PrefCategories: userAuthenticationContext?.UserDetails?.Person?.PrefCategories || "",
            PrefContentType: userAuthenticationContext?.UserDetails?.Person?.PrefContentType || "",
            HowDidYouHear: userAuthenticationContext?.UserDetails?.Person?.HowDidYouHear || "",
        }),
        [currentStep, setCurrentStep] = useState(0),

        popupRef = useRef<HTMLDivElement>(null),
        wrapperRef = useRef<HTMLDivElement>(null),
        stepRefs = useRef<HTMLDivElement[]>([]),
        stepCount = 4,

        contentTypeOptions = [
            "Videos",
            "Articles",
            "Podcasts",
            "Emails",
            "Texts"
        ],

        referrerOptions = [
            "Search Engine",
            "Friend / Word of Mouth",
            "Podcast / 3rd Party Media",
            "Social Media"
        ],

        handleCategoryClick = (categoryKey: string) => {
            setPersonData((prevData) => {
                const selectedCategories = prevData.PrefCategories ? prevData.PrefCategories.split(",") : [];
                if (selectedCategories.includes(categoryKey)) {
                    return {
                        ...prevData,
                        PrefCategories: selectedCategories.filter((c) => c !== categoryKey).join(","),
                    };
                } else if (selectedCategories.length < 3) {
                    return {
                        ...prevData,
                        PrefCategories: selectedCategories.length === 0 ? categoryKey : [...selectedCategories, categoryKey].join(","),
                    };
                }
                return prevData;
            });
        },

        handleContentTypeClick = (type: string) => {
            setPersonData((prevData) => {
                const selectedTypes = prevData.PrefContentType ? prevData.PrefContentType.split(",") : [];
                if (selectedTypes.includes(type)) {
                    return {
                        ...prevData,
                        PrefContentType: selectedTypes.filter((t) => t !== type).join(","),
                    };
                } else if (selectedTypes.length < 3) {
                    return {
                        ...prevData,
                        PrefContentType: selectedTypes.length === 0 ? type : [...selectedTypes, type].join(","),
                    };
                }
                return prevData;
            });
        },

        handleChangeHowDidYouHear = (value: string) => {
            setPersonData((prevData) => ({
                ...prevData,
                HowDidYouHear: value,
            }));
        },

        handleDropdownToggle = () => {
            setTimeout(() => {
                updateWrapperHeight();
            }, 5);
        },

        handleOutsideClick = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node) && allowClosePopUp) {
                handleClose();
            }
        },

        handleClose = () => {
            submitPersonData();
            setOnBoardPopUpActive(false);
        },

        handleNextStep = () => {
            submitPersonData();
            if (currentStep + 1 < stepCount) {
                setCurrentStep((prevStep) => prevStep + 1);
            }
        },

        handlePreviousStep = () => {
            if (currentStep > 0) {
                setCurrentStep((prevStep) => prevStep - 1);
            }
        },

        updateWrapperHeight = () => {
            if (wrapperRef.current && stepRefs.current[currentStep]) {
                const paddingOffset = window.innerWidth >= 584 ? 64 : 32;
                const activeStepHeight = stepRefs.current[currentStep].offsetHeight;
                wrapperRef.current.style.height = `${activeStepHeight + paddingOffset}px`;
            }
        },

        submitPersonData = async () => {
            if (userAuthenticationContext?.UserDetails?.Person) {
                const
                    completePersonData: IPerson = {
                        ...userAuthenticationContext?.UserDetails?.Person,
                        ...personData,
                        IsOnboarded: true
                    },
                    token = userAuthenticationContext?.UserDetails?.Access_Token,
                    abortController = new AbortController();

                try {
                    await AddOrEditPerson(completePersonData, token ? token : "", abortController);
                } catch (error) {
                    console.error("Error submitting preferred categories:", error);
                }
            }
        };

    useEffect(() => {
        const checkIfUserIsOnboarded = async () => {
            try {
                const boolResponse = await GetIsOnboarded(userAuthenticationContext?.UserDetails?.AspNetUserId);
                if (boolResponse === false) {
                    setOnBoardPopUpActive(true);
                }
            } catch (error) {
                console.error("Error checking user onboarded status:", error);
            }
        }

        if (userAuthenticationContext?.UserDetails?.AspNetUserId) {
            setPersonData({
                ...personData,
                ...userAuthenticationContext?.UserDetails?.Person
            });

            checkIfUserIsOnboarded();
        }
    }, [userAuthenticationContext?.UserDetails]);

    useEffect(() => {
        if (onBoardPopUpActive) {
            document.addEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [onBoardPopUpActive]);

    useEffect(() => {
        updateWrapperHeight();

        const handleResize = () => {
            updateWrapperHeight(); // Recalculate height on window resize
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [currentStep]);

    return (
        <UserOnboardingWrapper className={`ickonic--user-onboard ${onBoardPopUpActive ? 'is-active' : 'is-not-active'}`}>
            <div ref={popupRef}>
                <div className="user-onboard__wrapper u-relative_hidden" ref={wrapperRef}>
                    <div className="user-onboard__wrapper-window u-relative_hidden">
                        <div
                            className={`user-onboard__steps user-onboard__steps--${currentStep}`}
                            style={{
                                transform: `translateX(-${100 / stepCount * currentStep}%)`,
                                width: `${stepCount}00%`,
                            }}
                        >
                            <div
                                className={`user-onboard__step user-onboard__step-one ${currentStep === 0 ? 'is-active' : 'is-not-active'}`}
                                ref={(el) => (stepRefs.current[0] = el!)}
                            >
                                <h1>Hey Family! ... Question Everything!</h1>
                                <h3>Welcome to bold new informative content at your fingertips</h3>
                                <div className="user-onboard__divider"/>
                                <p>Tell us what categories are most interesting to you (max 3):</p>
                                <div className="multi-select-buttons">
                                    {Object.entries(ICategories).map(([key, category]) => (
                                        <button
                                            key={key}
                                            className={`ickonic-button ${personData.PrefCategories?.split(",").includes(key) ? 'is-active' : 'is-not-active'} ${personData.PrefCategories && personData.PrefCategories?.split(",").length >= 3 && !personData.PrefCategories?.split(",").includes(key) ? 'dimmed' : 'is-not-dimmed'}`}
                                            onClick={() => handleCategoryClick(key)}
                                            style={{
                                                boxShadow: `inset 0 0 0 ${personData.PrefCategories && personData.PrefCategories?.split(",").includes(key) ? '2px' : '1px'} ${personData.PrefCategories?.split(",").includes(key) ? category.colour : 'rgba(255,255,255,0.3)'}`,
                                                opacity: personData.PrefCategories && personData.PrefCategories?.split(",").length >= 3 && !personData.PrefCategories?.split(",").includes(key) ? 0.2 : 1,
                                            }}
                                        >
                                            {category.primaryName}
                                        </button>
                                    ))}
                                </div>

                                <ButtonPillUppercase
                                    label="Next Step"
                                    className={`ickonic-button__next-step ${personData.PrefCategories !== '' && personData.PrefCategories !== null ? 'is-active' : 'is-not-active'}`}
                                    link=""
                                    callbackFunction={personData.PrefCategories !== '' && personData.PrefCategories !== null ? handleNextStep : () => {
                                    }}
                                />
                            </div>

                            <div
                                className={`user-onboard__step user-onboard__step-two ${currentStep === 1 ? 'is-active' : 'is-not-active'}`}
                                ref={(el) => (stepRefs.current[1] = el!)}
                            >
                                <div className="user-onboard__heading-wrapper">
                                    <h2>How do you prefer to consume content?</h2>
                                    {/*<span className="user-onboard__close" onClick={handleClose}>Skip for now</span>*/}
                                </div>

                                <div className="user-onboard__divider"/>
                                <div className="multi-select-buttons">
                                    {contentTypeOptions.map((type, index) => (
                                        <button
                                            key={index}
                                            className={`ickonic-button ${personData.PrefContentType?.split(",").includes(type) ? 'is-active' : 'is-not-active'} ${personData.PrefContentType && personData.PrefContentType?.split(",").length >= 3 && !personData.PrefContentType?.split(",").includes(type) ? 'dimmed' : 'is-not-dimmed'}`}
                                            onClick={() => handleContentTypeClick(type)}
                                            style={{
                                                boxShadow: `inset 0 0 0 ${personData.PrefContentType && personData.PrefContentType?.split(",").includes(type) ? '2px' : '1px'} ${personData.PrefContentType?.split(",").includes(type) ? Colours.IckonicPink : 'rgba(255,255,255,0.3)'}`,
                                                opacity: personData.PrefContentType && personData.PrefContentType?.split(",").length >= 3 && !personData.PrefContentType?.split(",").includes(type) ? 0.2 : 1,
                                            }}
                                        >
                                            {type}
                                        </button>
                                    ))}
                                </div>

                                <div className="user-onboard__button-wrapper">
                                    <ButtonPillUppercase
                                        label="Back"
                                        link=""
                                        className="ickonic-button__prev-step ickonic-button__half-width"
                                        callbackFunction={handlePreviousStep}
                                    />

                                    <ButtonPillUppercase
                                        label="Next"
                                        link=""
                                        className={`ickonic-button__next-step ickonic-button__half-width ${personData.PrefContentType !== '' && personData.PrefContentType !== null ? 'is-active' : 'is-not-active'}`}
                                        callbackFunction={personData.PrefContentType !== '' && personData.PrefContentType !== null ? handleNextStep : () => {}}
                                    />
                                </div>
                            </div>

                            <div
                                className={`user-onboard__step user-onboard__step-three ${currentStep === 2 ? 'is-active' : 'is-not-active'}`}
                                ref={(el) => (stepRefs.current[2] = el!)}
                            >
                                <div className="user-onboard__heading-wrapper">
                                    <h2>How did you hear about us?</h2>
                                    {/*<span className="user-onboard__close" onClick={handleClose}>Skip for now</span>*/}
                                </div>

                                <div className="user-onboard__divider"/>

                                <InputDropDown
                                    options={referrerOptions}
                                    selectedValue={personData.HowDidYouHear || ""}
                                    onChange={value => handleChangeHowDidYouHear(value)}
                                    onClick={handleDropdownToggle}
                                    absolutePosition={false}
                                    placeholder="Select an option"
                                />

                                <div className="user-onboard__button-wrapper">
                                    <ButtonPillUppercase
                                        label="Back"
                                        link=""
                                        className="ickonic-button__prev-step ickonic-button__half-width"
                                        callbackFunction={handlePreviousStep}
                                    />

                                    <ButtonPillUppercase
                                        label="Finish"
                                        link=""
                                        className={`ickonic-button__finish-step ickonic-button__half-width ${personData.HowDidYouHear !== '' && personData.HowDidYouHear !== null ? 'is-active' : 'is-not-active'}`}
                                        callbackFunction={personData.HowDidYouHear !== '' && personData.HowDidYouHear !== null ? handleNextStep : () => {
                                        }}
                                    />
                                </div>
                            </div>

                            <div
                                className={`user-onboard__step user-onboard__step-four ${currentStep === 3 ? 'is-active' : 'is-not-active'}`}
                                ref={(el) => (stepRefs.current[3] = el!)}
                            >
                                <div className={`user-onboard__gradient-circle ${currentStep === 3 ? 'is-active' : 'is-not-active'}`}>
                                    <div className="user-onboard__gradient-mask"></div>
                                    <div className="user-onboard__gradient-cover"></div>
                                    {IconCheckMark({
                                        defs: (
                                            <defs>
                                                <linearGradient id="grad1" x1="0%" y1="100%" x2="100%" y2="0%">
                                                    <stop offset="0%" stopColor="rgba(229,174,44,1)" stopOpacity="1"/>
                                                    <stop offset="100%" stopColor="rgba(187,54,184,1)" stopOpacity="1"/>
                                                </linearGradient>
                                            </defs>
                                        ),
                                        iconFill: 'none',
                                        stroke: 'url(#grad1)'
                                    })}
                                </div>

                                <div className="user-onboard__heading-wrapper">
                                    <h1>Thank you for your submission!</h1>
                                </div>

                                <p>
                                    We'll use this information maximize your Ickonic experience. Until then, let's get
                                    you streaming!
                                </p>

                                <ButtonPillUppercase
                                    label="Start Watching"
                                    link=""
                                    className={`ickonic-button__finish-step is-active`}
                                    callbackFunction={handleClose}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserOnboardingWrapper>
    );
};

export default UserOnboarding;
